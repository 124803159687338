@use 'styles/mixins';
@use 'styles/variables' as *;

.headerUSP {
  width: 100vw;
  max-width: 100%;
  background-color: $keune-gold;
  color: $keune-black;
  font-size: 1.2rem;
  height: $announcementBarHeight;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mixins.media {
    display: flex;
    justify-content: space-evenly;
    padding: 0 3.6rem;
    text-align: center;
    margin-top: 0.5rem;
    font-size: 1.4rem;
  }

  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    @include mixins.media {
      width: auto;
      text-align: center;
    }
    .uspIcon {
      margin-right: 0.5rem;
    }
  }
}

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  @include mixins.media {
    flex-direction: row;
  }
}

.banner {
  position: relative;
  max-height: calc(100vh - $headerHeight - $announcementBarHeight);
  overflow: hidden;
  width: 100%;
  margin-top: 3.6rem;
  @include mixins.media {
    width: 50vw;
    margin-top: 0;
  }
}

.leftContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.6rem;
  width: 100%;
  @include mixins.media {
    width: 50vw;
  }
}

.pageNotFoundText {
  @include mixins.headlineTypography;
  text-align: center;
  font-size: 2rem;
  line-height: 2.8rem;
  @include mixins.media {
    font-size: 3.2rem;
    line-height: 4.5rem;
  }
}

.searchLink {
  @include mixins.headlineTypography;
  text-decoration: underline;
  font-size: 1.4rem;
  margin-top: 3.6rem;
  @include mixins.media {
    font-size: 1.6rem;
  }
}

.backToHomeButton{
  margin-top: 3.6rem;
}

.iconContainer{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 7.2rem;
  .icon {
    width: 10rem;
    margin: 0 1rem;
    @include mixins.media {
      width: 12rem;
      margin: 0 2rem;
    }
  }
}